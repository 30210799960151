import 'core-js/actual/get-iterator'
// Polyfills for v-tooltip
import 'core-js/actual/object/assign'
import 'core-js/actual/symbol'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

// Polyfill for svgicon
if (isIE11) {
  require('innersvg-polyfill')
}

// Polyfill console functions for when IE devtools don't exist
if (!window.console || Object.keys(window.console).length === 0) {
  window.console = {
    log: function () {},
    info: function () {},
    error: function () {},
    warn: function () {},
  }
}

import 'Styles/tailwind.sass.scss'



// FROM https://gist.github.com/leonid-chernyavskiy/a08395005da5eacf8f2f293ce544f594
// see https://github.com/vuelidate/vuelidate/issues/460
// and https://github.com/vuelidate/vuelidate/issues/874
// and https://github.com/vuelidate/vuelidate/issues/302

/*
 Solves $params === null (early loading) bug
 https://github.com/vuelidate/vuelidate/issues/302
 https://github.com/vuelidate/vuelidate/issues/874
 this patch adds window['vuelidate'].withParams, which is runtime proxy to the module's withParams function
 When using vuelidate this must be the first import:
 import * as vuelidate from 'vuelidate';
 window['_patchVuelidate'](vuelidate);
 Next function _must_ be first at entry point (webpack, ex. page entry point) - to be always at the commons chunk:
 */


//  (function() {
//   window['_patchVuelidate'] = function _patchVuelidate(module) {
//       if (window['vuelidate'].hasOwnProperty('_module')) {
//           window['vuelidate']._module = module;
//           window['vuelidate'] = module;
//       }
//   };

//   window['vuelidate'] = window['vuelidate'] || (function() {
//       function anotherFakeWithParams(paramsOrClosure, maybeValidator) {
//           if (typeof paramsOrClosure === 'object' && maybeValidator !== undefined) {
//               return maybeValidator;
//           }

//           return paramsOrClosure(function() {});
//       }

//       // Early loaded chunks will have this function;
//       // it is as proxy to the module's function, which is created by _patchVuelidate,
//       // or to the anotherFakeWithParams function
//       function dynamicWithParams() {
//           return (window['vuelidate'] && window['vuelidate'] !== fakeVuelidate
//               ? window['vuelidate'].withParams
//               : anotherFakeWithParams
//           ).apply(null, arguments);
//       }

//       const fakeVuelidate = {
//           _module: undefined,
//           withParams: dynamicWithParams,
//       };

//       return fakeVuelidate;
//   })();
// })();

// import * as vuelidate from 'vuelidate'
// console.log('vuelidate app', vuelidate)
// window['_patchVuelidate'](vuelidate)